
import SpriteView from '~/components/sprite-view';

export default {
  name: 'EffectCustomSpark',
  components: {
    SpriteView,
  },
  methods: {
    getSparkStyle() {
      return {
        animationDelay: `${Math.random() * 3}s`,
      };
    },
  },
};
